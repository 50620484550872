import React, {useState} from "react";
import {
  Button,
  Form,
  Alert
} from "react-bootstrap";
import axios from 'axios'
import {apiUrl} from './utils'

export default function Auth({fetch_, onAuth}) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)

  const changeUsername = e => setUsername(e.target.value)
  const changePassword = e => setPassword(e.target.value)

  const submitLogin = async e => {
    e.preventDefault();
    setError(null)
    // const {code, message, data} = await fetch_('login', {method: 'post', data: formData})
    try {
      const res = await axios(`${apiUrl}/login`, {
        method: 'post',
        data: {username, password}
      })
      onAuth(res.data)
    } catch (res) {
      return setError(res.response.data.detail)
    }
  };

  return <div>
    <Form onSubmit={submitLogin}>
      <Form.Group controlId="username">
        <Form.Label>Username</Form.Label>
        <Form.Control
          type="text"
          placeholder="Username"
          required
          value={username}
          onChange={changeUsername}
        />
      </Form.Group>

      <Form.Group controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Password"
          required
          value={password}
          onChange={changePassword}
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        Login
      </Button>
    </Form>
    {error && <Alert variant="danger">{error}</Alert>}
  </div>
}