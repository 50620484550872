import ReactTable, {NumberRangeColumnFilter, SelectColumnFilter} from "./ReactTable";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import moment from "moment";
import _ from 'lodash'

const dateFmt = d => moment(d).format('YYYY-MM-DD')

const dateCol = k => {
  return {
    accessor: k,
    Cell: ({row}) => _.isUndefined(row.original[k]) ? null : dateFmt(row.original[k])}
}
const numCol = (k, range=true) => {
  const col = {
    accessor: k,
    sortType: 'basic',
    Cell: ({row}) => _.isUndefined(row.original[k]) ? null : row.original[k].toFixed(3)
  }
  if (range) {
    col.filter = 'between'
    col.Filter = NumberRangeColumnFilter
  }
  return col
}
const titleCol = () => {
  return {
    Header: 'Title',
    accessor: 'title',
    Cell: ({row}) => _.isUndefined(row.original.title) ? null : <>
      {row.original.title}<br/>
      <small className='text-muted'>{row.original.title_clean}</small>
    </>
  }
}
const selectCol = k => {
  return {
    accessor: k,
    filter: 'includes',
    Filter: SelectColumnFilter
  }
}

const mainCols = [
  {Header: 'ID', accessor: 'id'},
  titleCol(),
  {Header: 'Choose', ...selectCol('choose')},
  {Header: '🥇Title', ...numCol('score_title')},
  {Header: '🥇Body', ...numCol('score_body')},
  {Header: '🥇Final', ...numCol('score')},
  {Header: '⌚Date', ...dateCol('published')},
  {Header: '⌚Age', ...numCol('days_old')},
  {Header: '⌚Penalty', ...numCol('age_penalty')},
]


export function Reports ({fetch_}) {
  const [rows, setRows] = useState([])

  const fetchRows = async () => {
    let data = await fetch_('reports', {params: {limit: 20}}, true)
    setRows(data) // load some for viewing first
    data = await fetch_('reports', {}, true)
    setRows(data) // then all of them
  }

  useEffect(() => {
    fetchRows()
  }, [])

  const columns = React.useMemo(
    () => [
      {Header: 'ID', accessor: 'id'},
      {Header: 'Title', accessor: 'title'},
      {Header: 'Published', ...dateCol('published')},
    ], [rows])

  return <div>
    <h3>Reports</h3>
    <ReactTable rows={rows} columns={columns} link='/reports/:id' />
  </div>
}


export function Report({fetch_, opts, children}) {
  const [report, setReport] = useState({})
  let [sims, setSims] = useState([])
  let {id} = useParams()

  const fetchReport = async () => {
    const data = await fetch_(`reports/${id}`)
    setReport(data)
  }

  const fetchRows = async () => {
    let url = `similars/${id}`
    let data = await fetch_(url, {params: {limit: 20}})
    setSims(data)  // load some immediately for view
    data = await fetch_(url)
    setSims(data) // while loading all the rows concurrently
  }

  useEffect(() => {
    fetchReport()
    fetchRows()
  }, [opts])

  const columns = React.useMemo(() => mainCols, [sims])

  const extra = {initialState: {
    sortBy: [{id: 'score', desc: true}]
  }}

  return <div>
    <h3>{report.title} <span className='text-muted'>- {id}</span></h3>
    <p className='text-muted'>{report.title_clean}</p>
    <p>⌚{dateFmt(report.published)} | Age {report.days_old} | AgeCap {report.age_cap}</p>
    {children}
    <ReactTable columns={columns} rows={sims} extra={extra} />
  </div>
}

export function Users({fetch_}) {
  const [rows, setRows] = useState([])

  const fetchRows = async () => {
    let data = await fetch_('users', {params: {limit: 20}})
    setRows(data) // load some first
    data = await fetch_(`users`)
    setRows(data) // then the rest
  }

  useEffect(() => {
    fetchRows()
  }, [])

  const columns = React.useMemo(
    () => [
      {Header: 'ID', accessor: 'id'},
      {Header: 'Reports read', ...numCol('ct')},
    ], [rows])

  return <div>
    <h3>Users</h3>
    <ReactTable rows={rows} columns={columns} link='/users/:id' />
  </div>
}

export function User({fetch_, opts, children}) {
  const [reads, setReads] = useState([])
  const [sims, setSims] = useState([])
  let {id} = useParams()

  const fetchRows = async () => {
    let data = await fetch_(`users/${id}`)
    setReads(data)
    data = await fetch_(`personalizations/${id}`)
    setSims(data)
  }

  useEffect(() => {
    fetchRows()
  }, [opts])

  const readsCols = React.useMemo(
    () => [
      {Header: 'ID', accessor: 'id'},
      titleCol(),
      {Header: 'Date', ...dateCol('published')},
      {Header: 'Age', accessor: 'days_old'},
    ],
    [reads]
  )

  const simsCols = React.useMemo(() => mainCols, [sims])

  const readsExtra = {initialState: {
    pageSize: 5
  }}
  const simsExtra = {initialState: {
    sortBy: [{id: 'score', desc: true}]
  }}

  return <div>
    <h3>User {id} reads</h3>
    <ReactTable rows={reads} columns={readsCols} extra={readsExtra} />
    <h3>Personalizations</h3>
    {children}
    <ReactTable rows={sims} columns={simsCols} extra={simsExtra} />
  </div>
}