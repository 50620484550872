import React, {useEffect, useState} from 'react';
import _ from 'lodash'
import {
  Navbar,
  Nav,
  Container
} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import axios from 'axios'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import {Report, Reports, User, Users} from './Tables'
import Auth from './Auth'
import Options from './Options'
import {apiUrl} from './utils'

// axios.interceptors.request.use(
//   config => {
//     const { origin } = new URL(config.url);
//     const allowedOrigins = [apiUrl];
//     const token = JSON.parse(localStorage.getItem('jwt'));
//     if (allowedOrigins.includes(origin)) {
//       config.headers.authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );


function App() {
  const [jwt, setJwt] = useState(localStorage.getItem('access'));
  const [opts, setOpts] = useState({
    choose: null,
    limit: null,
    debug: true,

    age_cap: 3,
    title_ratio: .25
  })

  const handleError = (err) => {
    if (err.response.status >= 400 && err.response.status < 500) {
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
      window.location.href = '/'
    }
  }

  const fetch_ = async (route, overrides) => {
    const defaults = {
      url: `${apiUrl}/${route}`,
      method: 'get',
      params: {},
      headers: {}
    }

    if (jwt) {
      // Refresh token
      try {
        const refresh = localStorage.getItem("refresh")
        const {data} = await axios({
          url: `${apiUrl}/refresh`,
          method: 'post',
          headers: {authorization: `Bearer ${refresh}`}
        })
        const {access_token} = data
        localStorage.setItem('access', access_token);
        defaults.headers.Authorization = `Bearer ${access_token}`;
      } catch (err) {
        handleError(err)
      }
    }

    // const basicOpts = route.match(/(similars\/.*)|(reports$)|(personalizations\/.*)/)
    // if (useOpts) {
    _.each(opts, (v, k) => {
      if (v) { defaults.params[k] = v }
    })
    // }

    try {
      const {data} = await axios(_.merge(defaults, overrides))
      return data
    } catch (err) {
      handleError(err)
    }
  }

  const onAuth = (jwt) => {
    console.log("onAuth", jwt)
    if (jwt.access_token) {
      localStorage.setItem('access', jwt.access_token);
      setJwt(jwt);
    }
    if (jwt.refresh_token) {
      localStorage.setItem('refresh', jwt.refresh_token);
    }
  };

  const renderNav = () => <>
    <Navbar bg="dark" variant="dark">
      <Nav className="mr-auto">
        <LinkContainer to="/reports">
          <Nav.Link>Reports</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/users">
          <Nav.Link>Users</Nav.Link>
        </LinkContainer>
      </Nav>
    </Navbar>
  </>

  const options = <Options opts={opts} setOpts={setOpts} fetch_={fetch_}/>

  const renderAuth = () => {
    return <Container fluid>
      <Auth fetch_={fetch_} onAuth={onAuth} />
    </Container>
  }

  const renderApp = () => <>
    {renderNav()}
    <br/>
    <Container fluid>
      <Switch>
        <Route path='/reports' exact>
          <Reports fetch_={fetch_} />
        </Route>
        <Route path='/reports/:id'>
          <Report fetch_={fetch_} opts={opts}>
            {options}
          </Report>
        </Route>
        <Route path='/users' exact>
          <Users fetch_={fetch_}/>
        </Route>
        <Route path='/users/:id'>
          <User fetch_={fetch_} opts={opts}>
            {options}
          </User>
        </Route>
        <Route path='/' exact>
          <p>Choose "Users" or "Reports" above</p>
        </Route>
      </Switch>
    </Container>
  </>

  return <div>
    <Router>
      {jwt ? renderApp() : renderAuth()}
    </Router>
  </div>

}

export default App;