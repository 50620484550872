import React, {useEffect, useState} from "react";
import axios from "axios";
import {Accordion, Badge, Button, Card, Col, Form, Row} from "react-bootstrap";

export default function Options({opts, setOpts, fetch_}) {
  const [chooses, setChooses] = useState([])
  // mirror global opts to local, we'll use a submit button to prevent debounce sql errors
  const [opts_, setOpts_] = useState(opts)

  const fetchChooses = async () => {
    const data = await fetch_('chooses')
    setChooses(data)
  }

  useEffect(() => {
    fetchChooses()
  }, [])

  const changeValue = k => e => setOpts_({...opts_, [k]: e.target.value})
  const changeDebug = e => setOpts_({...opts_, debug: e.target.checked})
  const changeChoose = e => setOpts_({...opts_, choose: e.target.name})

  const submit = e => {
    e.preventDefault()
    setOpts(opts_)
  }

  const form = <Form onSubmit={submit}>
    <Row lg={2} xs={1}>
      <Col>
        <h4>For Admins</h4>
        <Form.Group controlId="age_cap">
          <Form.Label>Age Cap</Form.Label>
          <Form.Control
            value={opts_.age_cap}
            onChange={changeValue('age_cap')}
            type="number"
            placeholder="Number of years (can be decimal)"
          />
          <Form.Text>Number of years (eg .5, 3) where the age-penalty caps off, and anything past that is score=0.</Form.Text>
        </Form.Group>
        <Form.Group controlId="title_ratio">
          <Form.Label>Title Ratio</Form.Label>
          <Form.Control
            value={opts_.title_ratio}
            onChange={changeValue('title_ratio')}
            type="number"
            placeholder="How much weight does score_title have?"
          />
          <Form.Text>How much weight does score_title have? score_body takes up the rest. Eg, if you use .25 here it's title*.25+body*.75. Can be 0, can be 1.</Form.Text>
        </Form.Group>
      </Col>
      <Col>
        <h4>For Seth</h4>
        <Form.Text>These options are set on the request, rather than using filters client-side, just in case you want to sanity-check.</Form.Text>
        <fieldset>
          <Form.Group>
            <Form.Label>Choose</Form.Label>
            {chooses.map(c => <>
              <Form.Check
                onChange={changeChoose}
                key={c.key}
                type="radio"
                label={c.title}
                name={c.key}
                checked={c.key === opts_.choose}
                id={`choose-${c.key}`}
              />
              <Form.Text>{c.description}</Form.Text>
            </>)}
          </Form.Group>
        </fieldset>
        <Form.Group controlId="limit">
          <Form.Label>Limit</Form.Label>
          <Form.Control
            value={opts_.limit}
            onChange={changeValue('limit')}
            type="number"
            placeholder="Limit results"
          />
        </Form.Group>
        <Form.Group controlId="debug">
          <Form.Check
            checked={opts_.debug}
            onChange={changeDebug}
            label="Details"
          />
          <Form.Text>Show row details so admins can analyze results. Unchecking this shows just the API results Seth will see (faster SQL)</Form.Text>
        </Form.Group>
      </Col>
    </Row>
    <Button variant='primary' type='submit'>Submit</Button>
  </Form>

  return <Accordion>
    <Card border='dark'>
      <Card.Header>
        <Accordion.Toggle as={Button} variant="link" eventKey="0">
          Options
        </Accordion.Toggle>
        <div><small className='text-muted'>
          Choose: <Badge variant="secondary">{opts_.choose}</Badge>{' '}
          Limit: <Badge variant="secondary">{opts_.limit}</Badge>{' '}
          Details: <Badge variant="secondary">{opts_.debug ? 'true' : 'false'}</Badge>
        </small></div>
      </Card.Header>
      <Accordion.Collapse eventKey="0">
        <Card.Body>{form}</Card.Body>
      </Accordion.Collapse>
    </Card>
  </Accordion>
}